import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      // invoiceTab: "tab-other",
      invoice: 0,
      jobId: 0,
      quotationId: 0,
      pageLoading: true,
      particuleFieldLoading: false,
      invoiceDatePicker: false,
      sentDatePicker: false,
      dueDatePicker: false,
      deleteValid: false,
      deleteLoading: false,
      deleteDialog: false,
      deleteValidateText: null,
      deleteLineItemDialog: false,
      lineItemDialog: false,
      deleteDetail: {},
      detail: {
        status: 0
      },
      customer: {},
      property: {},
      billing: {},
      project: {},
      quotation: {},
      ticket: {},
      contact_person: {},
      line_items: {},
      paymentDueList: [],
      moreActions: [],
      updateDetailDialog: {
        title: false,
        reference: false,
        attention: false,
        invoice_date: false,
        due_date: false,
        sent_date: false
      },
      updateDetail: {
        title: null,
        reference: null,
        attention: null,
        invoice_date: null,
        due_date: null,
        sent_date: null
      }
    };
  },
  watch: {
    deleteLineItemDialog(param) {
      if (!param) {
        this.deleteDetail = new Object();
      }
    }
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.editInvoice();
          break;
        case "duplicate":
          _this.duplicateInvoice();
          break;
        case "send_as_email":
          _this
            .patchInvoice({ status: 4 })
            .then(() => {
              _this.loadContent();
            })
            .catch(error => {
              _this.logError(error);
            });
          break;
        case "mark_as_paid":
          _this
            .patchInvoice({ status: 3 })
            .then(() => {
              _this.loadContent();
            })
            .catch(error => {
              _this.logError(error);
            });
          break;
        case "mark_as_unpaid":
          _this
            .patchInvoice({ status: 2 })
            .then(() => {
              _this.loadContent();
            })
            .catch(error => {
              _this.logError(error);
            });
          break;
        case "mark_as_cancelled":
          _this
            .patchInvoice({ status: 5 })
            .then(() => {
              _this.loadContent();
            })
            .catch(error => {
              _this.logError(error);
            });
          break;
        case "download_pdf":
          window.open(
            process.env.VUE_APP_API_URL +
              "invoice/" +
              _this.detail.id +
              "/pdf/download",
            "_blank"
          );
          break;
        case "print":
          window.open(
            process.env.VUE_APP_API_URL +
              "invoice/" +
              _this.detail.id +
              "/print",
            "_blank"
          );
          break;
      }
    },
    editInvoice() {
      const _this = this;
      if (_this.getPermission("invoice:update")) {
        _this.$router.push(
          _this.getDefaultRoute("invoice.update", {
            params: {
              id: _this.detail.id
            },
            
            query: {
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              property: _this.lodash.toSafeInteger(
                _this.detail.property.property
              )
            }
          })
        );
      }
    },
    duplicateInvoice() {
      const _this = this;
      if (_this.getPermission("invoice:create")) {
        _this.$router.push(
          _this.getDefaultRoute("invoice.create", {
            query: {
              duplicate: _this.detail.id,
              customer: _this.lodash.toSafeInteger(
                _this.detail.customer.customer
              ),
              contact_person: _this.lodash.toSafeInteger(
                _this.detail.contact_person.contact_person
              ),
              property: _this.lodash.toSafeInteger(
                _this.detail.property.property
              )
            }
          })
        );
      }
    },
    patchInvoice(data) {
      const _this = this;
      if (_this.canUpdateInvoice && _this.getPermission("invoice:update")) {
        return new Promise((resolve, reject) => {
          _this.$store
            .dispatch(PATCH, { url: "invoice/" + _this.detail.id, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    updateField(type) {
      const _this = this;
      if (_this.canUpdateInvoice && _this.getPermission("invoice:update")) {
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        if (type == "payment_due") {
          _this.updateDetail[type] = _this.detail[type].id;
        } else {
          _this.updateDetail[type] = _this.detail[type];
        }
        setTimeout(function() {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        title: false,
        reference: false,
        attention: false,
        invoice_date: false,
        due_date: false,
        sent_date: false,
        payment_due: false
      };
      this.updateDetail = {
        title: null,
        reference: null,
        attention: null,
        invoice_date: null,
        due_date: null,
        sent_date: null,
        payment_due: null
      };
    },
    getAttributes(attribute) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "invoice/options",
          data: { duplicate: 0, attribute }
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.payment_terms) === false) {
            _this.paymentDueList = data.payment_terms;
          }
        })
        .catch(error => {
          _this.logError(error);
        });
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      _this.invoiceDatePicker = false;
      _this.sentDatePicker = false;
      _this.dueDatePicker = false;
      _this
        .patchInvoice(_this.updateDetail)
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.particuleFieldLoading = false;
          _this.onEscParticularField();
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getInvoice()
        .then(response => {
          _this.invoice = response.invoice.id;
          _this.detail = response.invoice;
          _this.project = response.project;
          _this.quotation = response.quotation;
          _this.ticket = response.ticket;
          _this.moreActions = _this.detail.more_actions;
          if (_this.lodash.isEmpty(_this.detail.ticket) === false) {
            _this.jobId = _this.lodash.toSafeInteger(_this.detail.ticket.id);
          }
          if (_this.lodash.isEmpty(_this.detail.quotation) === false) {
            _this.quotationId = _this.lodash.toSafeInteger(
              _this.detail.quotation.id
            );
          }
          _this.customer = response.customer;
          _this.property = response.property;
          _this.billing = response.billing;
          _this.contact_person = response.contact_person;
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Invoice", route: "invoice" },
            { title: "Detail" },
            { title: _this.detail.barcode }
          ]);
        })
        .catch(error => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.lineItemDialog = false;
          _this.pageLoading = false;
          _this.onEscParticularField();
        });
    },
    getFormattedDate(date) {
      return this.formatDate(date);
    },
    getInvoice() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "invoice/" + _this.invoice })
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Invoice",
        route: "invoice"
      },
      {
        title: "Detail"
      }
    ]);
    _this.loadContent();
    _this.getAttributes(["payment_terms"]);
  },
  created() {
    const _this = this;
    _this.invoice = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.invoice || _this.invoice <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.invoiceTab = _this.$route.query.tab;
    }
  },
  computed: {
    canUpdateInvoice() {
      if (this.detail && (this.detail.status == 3 || this.detail.status == 5)) {
        return false;
      }
      return this.getPermission("invoice:update");
    },
    invoiceTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.invoiceTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      }
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.property) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    BillingDetail() {
      let billingArr = new Array();
      if (this.billing) {
        if (this.billing.unit_no) {
          billingArr.push(this.billing.unit_no);
        }
        if (this.billing.street_1) {
          billingArr.push(this.billing.street_1);
        }
        if (this.billing.street_2) {
          billingArr.push(this.billing.street_2);
        }
        if (this.billing.zip_code) {
          billingArr.push(this.billing.zip_code);
        }
        if (this.CityBillingName) {
          billingArr.push(this.CityBillingName);
        }
        if (this.StateBillingName) {
          billingArr.push(this.StateBillingName);
        }
        if (this.CountryBillingName) {
          billingArr.push(this.CountryBillingName);
        }
      }
      return billingArr.join(", ");
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    }
  }
};
